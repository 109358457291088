export const SET_CURRENT_TAB = 'SocialSettingsTabView/SET_CURRENT_TAB';
export const GENERAL_SETTINGS_TAB_ID = 0;
export const BLOCK_USERS_TAB_ID = 1;

export function setCurrentTab(id) {
  return {
    type: SET_CURRENT_TAB,
    payload: { id }
  };
}
