import { linkify } from 'react-linkify';

export const LINKIFY_PROPERTIES = {
  rel: 'noopener noreferrer nofollow',
  target: '_blank'
};

export default function configureLinkify() {
  linkify
    .set({
      fuzzyLink: false, // disable links without protocol, because typos are placing links everywhere
      fuzzyIP: false,
      fuzzyEmail: true
    })
    .add('ftp:', null) // disable ftp
    .add('//', null); // disable protocol-relative links
}
