// Get Date representing the Gregorian Western Easter Sunday for local timezone
// https://stackoverflow.com/a/44480326
export function getEasterDate(year) {
  // Find the golden number.
  const a = year % 19;

  // Choose which version of the algorithm to use based on the given year.
  const b =
    2200 <= year && year <= 2299 ? (11 * a + 4) % 30 : (11 * a + 5) % 30;

  // Determine whether or not to compensate for the previous step.
  const c = b === 0 || (b === 1 && a > 10) ? b + 1 : b;

  // Use c first to find the month: April or March.
  const m = 1 <= c && c <= 19 ? 3 : 2;

  // Then use c to find the full moon after the northward equinox.
  const d = (50 - c) % 31;

  const date = new Date();
  date.setHours(0, 0, 0, 0);
  date.setFullYear(year);

  // Mark the date of that full moon—the "Paschal" full moon.
  date.setMonth(m, d);

  // Count forward the number of days until the following Sunday (Easter).
  date.setMonth(m, d + (7 - date.getDay()));

  return date;
}
