export const SET_CURRENT_TAB = 'SocialFriendFinderTabView/SET_CURRENT_TAB';
export const FRIENDS_TAB_ID = 1;
export const SEARCH_USER_TAB_ID = 2;

export function setCurrentTab(id) {
  return {
    type: SET_CURRENT_TAB,
    payload: { id }
  };
}
