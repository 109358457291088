import moment from 'moment';

// Convert date to ISO9075 MySQL DateTime format string. Pass the date to
// use or something convertable to a Date.
export function getIso9075DateString(date) {
  if (!date) {
    return '';
  } else if (!(date instanceof Date) && !moment.isMoment(date)) {
    date = new Date(date);
  }

  if (isNaN(date.valueOf())) {
    return '';
  }

  // Desired format is "YYYY-MM-DD HH:mm:ss.sss"
  return date.toISOString().replace('T', ' ').replace('Z', '');
}

// Convert an ISO9075 MySQL DateTime string to a JS Date object.
export function fromIso9075DateString(value) {
  if (!value || typeof value !== 'string') {
    return value;
  } else {
    return new Date(value.trim().replace(/\//g, '-').replace(' ', 'T') + 'Z');
  }
}

export function truncateSeconds(value) {
  if (!value) {
    return value;
  } else if (moment.isMoment(value)) {
    return moment(value).seconds(0).milliseconds(0);
  } else if (value instanceof Date) {
    return new Date(value).setSeconds(0, 0);
  } else {
    throw new Error('Expected date object, received ' + typeof value);
  }
}

export function isValidDate(value) {
  if (!value) {
    return false;
  } else if (moment.isMoment(value)) {
    return value.isValid();
  } else if (value instanceof Date) {
    return !isNaN(value);
  } else {
    return false;
  }
}
