import React from 'react';
import moment from 'moment';
import 'moment-timezone';
import { isDevEnv } from 'utils/constants';
import { retryingLoader } from 'utils/createLoader';
import { getEasterDate } from 'utils/holidays';
import { isLedWall } from 'utils/mode';
import { parseQuery } from 'utils/web';
import { themeLoadComplete } from './themeReducer';
import withTheme from './withTheme';

// What time of day the theme transitions
const TRANSITION_TIME = '8:00 am';

// What time zone theme transitions occur in
const TRANSITION_TIME_ZONE = 'America/Chicago';

// How far ahead the site calendar runs (used for dev site to run ahead)
const DEFAULT_OFFSET = !isDevEnv() ? 0 : 7 * 24 * 60 * 60 * 1000; // 7 days

// Allow query to override current theme or date calculation
let overrideQuery = parseQuery(window.location.search);
const overrideTheme = overrideQuery['theme-override'];
let overrideOffset =
  parseInt(overrideQuery['theme-offset'], 10) || DEFAULT_OFFSET;
const overrideDate = overrideQuery['theme-date'];
if (overrideDate) {
  const targetNow = moment(overrideDate, 'YYYY-MM-DD[T]HH:mm:ss', true);
  if (targetNow.isValid()) {
    overrideOffset = targetNow - moment();
  }
}
overrideQuery = null;

// How often the system should check if it is time for a new theme to start
const THEME_CHECK_RATE =
  overrideOffset !== DEFAULT_OFFSET ? 10000 : 5 * 60 * 1000; // 5 min

const DEFAULT_THEME = {
  name: 'default',
  loader: () => import(/* webpackChunkName: "theme-default" */ './default'),
  color: '#97131F' // $bethlehem-red
};

const CASINOVERSE_THEME = {
  name: 'casinoverse',
  loader: () =>
    import(/* webpackChunkName: "theme-led-wall" */ './casinoverse'),
  color: '#97131F' // $bethlehem-red
};

const THEMES = [
  {
    name: 'valentines-day',
    start: '1-15',
    end: '2-15',
    loader: () =>
      import(/* webpackChunkName: "theme-valentines-day" */ './valentinesDay'),
    color: '#5205bc' // $color-purple
  },
  {
    name: 'mardi-gras',
    start: '2-15',
    end: now => {
      const date = getEasterDate(now.year());
      date.setDate(date.getDate() - 47 + 1);
      const min = new Date(now.year(), 2 - 1, 16); // 2-16
      const end = date < min ? min : date;
      return end.getMonth() + 1 + '-' + end.getDate(); // 2022 => '3-2'
    },
    loader: () =>
      import(/* webpackChunkName: "theme-mardi-gras" */ './mardiGras'),
    color: '#4A0A9B' // $mardi-gras-purple
  },
  {
    name: 'st-patricks',
    start: '2-27',
    end: '3-19',
    loader: () =>
      import(/* webpackChunkName: "theme-st-patricks" */ './stPatricks'),
    color: '#1CB874' // $st-patricks-green
  },
  {
    name: 'easter',
    start: '3-19',
    end: now => {
      const date = getEasterDate(now.year());
      date.setDate(date.getDate() + 1);
      return date.getMonth() + 1 + '-' + date.getDate(); // 2022 => '4-18'
    },
    loader: () => import(/* webpackChunkName: "theme-easter" */ './easter'),
    color: '#1CB874' // $st-patricks-green
  },
  {
    name: 'fourth-of-july',
    start: '6-15',
    end: '7-5',
    loader: () =>
      import(/* webpackChunkName: "theme-fourth-of-july" */ './fourthOfJuly'),
    color: '#740000' // $xmas-crimson
  },
  {
    name: 'halloween',
    start: '10-1',
    end: '11-1',
    loader: () =>
      import(/* webpackChunkName: "theme-halloween" */ './halloween'),
    color: '#B14623' // $color-burnt-pumpkin
  },
  {
    name: 'thanksgiving',
    start: '11-1',
    end: '11-29',
    loader: () =>
      import(/* webpackChunkName: "theme-thanksgiving" */ './thanksgiving'),
    color: '#B14623' // $color-burnt-pumpkin
  },
  {
    name: 'christmas',
    start: '11-29',
    end: '12-26',
    loader: () =>
      import(/* webpackChunkName: "theme-christmas" */ './christmas'),
    color: '#740000' // $xmas-crimson
  },
  {
    name: 'new-years',
    start: '12-26',
    end: '1-2',
    loader: () =>
      import(/* webpackChunkName: "theme-new-years" */ './newYears'),
    color: '#740000' // $xmas-crimson
  }
];

function getNow() {
  if (overrideOffset) {
    return moment(Date.now() + overrideOffset);
  } else {
    return moment();
  }
}

function determineScheduledTheme() {
  if (overrideTheme) {
    return THEMES.find(t => t.name === overrideTheme) || DEFAULT_THEME;
  }
  if (isLedWall()) {
    return CASINOVERSE_THEME;
  }
  const now = getNow();
  const year = now.year();
  return (
    THEMES.find(t => {
      const start = typeof t.start === 'function' ? t.start(now) : t.start;
      const end = typeof t.end === 'function' ? t.end(now) : t.end;
      const startDate = moment.tz(
        year + '-' + start + ' ' + TRANSITION_TIME,
        'YYYY-M-D h:mm a',
        TRANSITION_TIME_ZONE
      );
      const endDate = moment.tz(
        year + '-' + end + ' ' + TRANSITION_TIME,
        'YYYY-M-D h:mm a',
        TRANSITION_TIME_ZONE
      );
      if (endDate < startDate) {
        // Runs across year boundary
        return now >= startDate || now < endDate;
      }
      return now >= startDate && now < endDate;
    }) || DEFAULT_THEME
  );
}

function performThemeCheck() {
  const scheduledTheme = determineScheduledTheme();
  if (scheduledTheme !== activeTheme) {
    loadTheme(scheduledTheme);
  }
}

let activeTheme = null;
let reduxStore = null;
let themeCheckInterval = null;

export const initializeThemeSystem = store => {
  reduxStore = store;
  loadTheme(determineScheduledTheme());
  clearInterval(themeCheckInterval);
  themeCheckInterval = setInterval(performThemeCheck, THEME_CHECK_RATE);
};

export const getDefaultedThemeColor = () =>
  (activeTheme && activeTheme.color) || DEFAULT_THEME.color;

export const getThemeComponent = componentId => {
  const themedComponent = props => {
    if (
      activeTheme &&
      activeTheme._module &&
      activeTheme._module[componentId]
    ) {
      const Component = activeTheme._module[componentId];
      return <Component {...props} />;
    } else {
      return null;
    }
  };
  return withTheme(themedComponent);
};

const sanitizeTheme = theme => ({ name: theme.name, color: theme.color });

let activeLoadingTheme = null;
function loadTheme(theme) {
  if (!theme._module) {
    if (!theme._loading) {
      activeLoadingTheme = theme;
      theme._loading = retryingLoader(theme.loader)()
        .then(loadedModule => {
          theme._module = loadedModule;
          theme._loading = false;
          if (activeLoadingTheme === theme) {
            setActiveTheme(theme);
          }
        })
        .catch(error => {
          theme._loading = false;
          console.warn('Failed to load theme:', error);
        });
    }
  } else {
    setActiveTheme(theme);
  }
}

function setActiveTheme(theme) {
  if (activeTheme) {
    const oldClass = 'theme-' + activeTheme.name;
    document.documentElement.classList.remove(oldClass);
    document.body.classList.remove(oldClass);
  }
  activeTheme = theme;
  const themeClass = 'theme-' + activeTheme.name;
  document.documentElement.classList.add(themeClass);
  document.body.classList.add(themeClass);
  reduxStore.dispatch(themeLoadComplete(sanitizeTheme(theme)));
}
