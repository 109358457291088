import MobileDetect from 'mobile-detect';
import { parseQuery } from 'utils/web';

/*
The React website can run in one of several primary modes:
- desktop-web: accessed from desktop computer browser
- mobile-web: accessed from mobile browser
- mobile-app: embedded in native mobile app webview
- led-wall: embedded in native app webview
- employee-web: employee admin-only view

There are also secondary groupings:
- mobile-device: any type of mobile device with touch-based input, includes mobile-web, mobile-app, and led-wall
- embedded: any mode that is embedded in a webview and needs to restrict links or popups, includes mobile-app and led-wall.
*/

export const mobileDetect = new MobileDetect(window.navigator.userAgent);
const mobileUserAgent = !!mobileDetect.mobile();
const mobileOs = mobileDetect.os();
const queryData = parseQuery(window.location.search);

const mobileApp = queryData.mkey === process.env.REACT_APP_MOBILE_SECRET;

const ledWall = queryData.hasOwnProperty('ledWallMode');

const employeeMobileApp = queryData.EmployeeApp === 'true';

const employeeWeb =
  /* true || /* For developing locally */
  window.location.origin.startsWith(process.env.REACT_APP_EMPLOYEE_ORIGIN);

export function initializeMode() {
  // Note: Keep _mixins.scss with-body-class() mixin updated with these classes
  if (isMobileDevice()) {
    document.documentElement.classList.add('mobile-device');
    document.body.classList.add('mobile-device');
    if (isMobileApp()) {
      document.documentElement.classList.add('mobile-app');
      document.body.classList.add('mobile-app');
    } else if (isLedWall()) {
      document.documentElement.classList.add('led-wall');
      document.body.classList.add('led-wall');
    } else {
      document.documentElement.classList.add('mobile-web');
      document.body.classList.add('mobile-web');
    }
  } else {
    document.documentElement.classList.add('desktop-web');
    document.body.classList.add('desktop-web');
  }

  if (isEmbedded()) {
    document.documentElement.classList.add('embedded');
    document.body.classList.add('embedded');
  }
}

export function isMobileApp() {
  return mobileApp;
}

export function isMobileWeb() {
  return mobileUserAgent && !mobileApp && !ledWall;
}

export function isMobileUserAgent() {
  return mobileUserAgent;
}

export function isLedWall() {
  return ledWall;
}

export function isEmployeeWeb() {
  return employeeWeb;
}

export function isEmployeeMobileApp() {
  return employeeMobileApp;
}

export function isMobileDevice() {
  return isMobileUserAgent() || isMobileApp() || isLedWall();
}

export function isEmbedded() {
  return isMobileApp() || isLedWall();
}

export function isAppleDevice() {
  return mobileOs === 'iOS';
}
