import { fromJS } from 'immutable';
import { FRIENDS_TAB_ID, SET_CURRENT_TAB } from './actions';

const initialState = fromJS({ tab: FRIENDS_TAB_ID });

export default function socialFriendFinderTabViewReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case SET_CURRENT_TAB:
      return state.set('tab', action.payload.id);

    default:
      return state;
  }
}
