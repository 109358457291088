export const SET_CURRENT_TAB = 'GameChatTabView/SET_CURRENT_TAB';
export const GAMECHAT_TAB_ID = 1;
export const FRIENDS_TAB_ID = 2;

export function setCurrentTab(id) {
  return {
    type: SET_CURRENT_TAB,
    payload: { id }
  };
}
