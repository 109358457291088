import { isMobileDevice } from 'utils/mode';

// Local fallback for when localstorage is disabled or full
const localData = {};
let localStorageAvailable = false;

try {
  const test = 'storage_enabled';
  localStorage.setItem(test, test);
  localStorage.removeItem(test);
  localStorageAvailable = true;
} catch (e) {
  console.error('Could not access local storage: ', e);
}

export function isLocalStorageAvailable() {
  return localStorageAvailable;
}

export function getLocalData(name, defaultValue = null, json = true) {
  let data = localData[name];
  try {
    const stored = localStorage.getItem(name);
    if (data !== stored) {
      localData[name] = stored;
      data = stored;
    }
  } catch (e) {
    if (localStorageAvailable) {
      localStorageAvailable = false;
      console.error('Could not read local storage: ', name, '. ', e);
    }
  }

  if (data === null || data === undefined) {
    return defaultValue;
  } else if (json) {
    return JSON.parse(data);
  } else {
    return data;
  }
}

export function setLocalData(name, value, json = true) {
  if (value === undefined) {
    value = null;
  }
  if (json) {
    value = JSON.stringify(value);
  }
  localData[name] = value;
  try {
    localStorage.setItem(name, value);
  } catch (e) {
    if (localStorageAvailable) {
      localStorageAvailable = false;
      console.error('Could not write local storage: ', name, '. ', e);
    }
  }
}

export function removeLocalData(name) {
  delete localData[name];
  try {
    localStorage.removeItem(name);
  } catch (e) {
    if (localStorageAvailable) {
      localStorageAvailable = false;
      console.error('Could not clear local storage: ', name, '. ', e);
    }
  }
}

export function mergeLocalData(name, obj) {
  setLocalData(Object.assign(getLocalData(), obj));
}

export const getLastGame = () => getLocalData('last_game', '');
export const setLastGame = gameName => setLocalData('last_game', gameName);

export const getGameSortBy = () => getLocalData('sortby_selection', '');
export const setGameSortBy = s => setLocalData('sortby_selection', s);

export const getGameFavorites = () => getLocalData('game_favorites', []);
export const setGameFavorites = list => setLocalData('game_favorites', list);
export const clearFavorites = () => removeLocalData('game_favorites');

export const getGameHistory = () => getLocalData('game_history', []);
export const setGameHistory = list => setLocalData('game_history', list);

export const getMaintenanceMode = () => getLocalData('maintenance_mode', {});
export const setMaintenanceMode = m => setLocalData('maintenance_mode', m);

export const getGameMaximized = () => !!getLocalData('game_maximized', false);
export const setGameMaximized = m => setLocalData('game_maximized', !!m);

export const getCasinoSounds = () =>
  !!getLocalData('casinoSounds', !isMobileDevice());
export const setCasinoSounds = s => setLocalData('casinoSounds', !!s);

export const getIntroTour = () => !!getLocalData('intro_tour', false);
export const enableIntroTour = () => setLocalData('intro_tour', true);
export const clearIntroTour = () => removeLocalData('intro_tour');

export const getRmgPopUpTriggered = () => !!getLocalData('rmg_popup', false);
export const setRmgPopUpTriggered = s => setLocalData('rmg_popup', !!s);

export const getWelcomeVideoRate = () => getLocalData('welcome_video', 0);
export const setWelcomeVideoRate = s => setLocalData('welcome_video', s);

export const getIpGeoLocationData = () => getLocalData('ip_geo2', {});
export const setIpGeoLocationData = data => setLocalData('ip_geo2', data);
removeLocalData('ip_geo'); // Clean up old data

export const getHiddenPinnedPosts = () => getLocalData('hidden_pinned', []);
export const setHiddenPinnedPosts = data => setLocalData('hidden_pinned', data);
export const clearHiddenPinnedPosts = () => removeLocalData('hidden_pinned');

export const getRecentSearches = () => getLocalData('recent_searches', []);
export const setRecentSearches = data => setLocalData('recent_searches', data);
