export const APP_ENV = process.env.REACT_APP_ENVIRONMENT;
export const APP_ENV_EMPLOYEE = 'employee';
export const APP_ENV_DEV = 'development';
export const APP_ENV_TEST = 'test';
export const APP_ENV_PROD = 'production';

export function isEmployeeEnv() {
  return APP_ENV === APP_ENV_EMPLOYEE;
}

export function isDevEnv() {
  return APP_ENV === APP_ENV_DEV;
}

export function isTestEnv() {
  return APP_ENV === APP_ENV_TEST;
}

export function isProdEnv() {
  return APP_ENV === APP_ENV_PROD;
}

export const APP_HOST = process.env.REACT_APP_HOST;

export const YOUTUBE_CHANNEL_URL = `https://www.youtube.com/channel/${process.env.REACT_APP_YOUTUBE_CHANNEL_ID}`;

export const FONT_FAMILY_BODY_LIST = [
  'Montserrat',
  'Helvetica',
  'Arial',
  'sans-serif'
];
export const FONT_FAMILY_TITLE_LIST = [
  'Lilita One',
  'Arial Black',
  'Helvetica',
  'Tahoma',
  'sans-serif'
];

// Call center phone number for general issues
export const SUPPORT_GUEST_SERVICE_PHONE = '866-946-3360';
export const SUPPORT_GUEST_SERVICE_PHONE_TEL = 'tel:+1-866-946-3360';

// Community manager phone number for WCC-specific issues
export const SUPPORT_SOCIAL_PHONE = '866-946-3387';
export const SUPPORT_SOCIAL_PHONE_TEL = 'tel:+1-866-946-3387';

export const VENDOR_AGS = 'AGS';
export const VENDOR_SPIN_GAMES = 'SpinGames';
export const VENDOR_RED_RAKE = 'RedRake';
export const VENDOR_WIND_CREEK = 'WindCreek';

export const PASSWORD_MIN_LENGTH = 8;
export const PASSWORD_MAX_LENGTH = 64;
export const DISPLAY_NAME_MIN_LENGTH = 2;
export const DISPLAY_NAME_MAX_LENGTH = 31;
export const ADMIN_DISPLAY_NAME_MAX_LENGTH = 50;

// Event types
export const PROMOTION_EVENT = 'promotions';
export const SPECIAL_EVENT = 'special';
export const ENTERTAINMENT_EVENT = 'entertainment';

// community event promotion types
export const BONUS_GAME = 'BONUS_GAME';
export const EARN_GET = 'EARN_GET';
export const ELECTRONIC_DRAWING = 'ELECTRONIC_DRAWING';
export const FOOD_CREDIT = 'FOOD_CREDIT';
export const HOT_SEATS = 'HOT_SEATS';
export const TICKET_DRAWING = 'TICKET_DRAWING';
export const HOLIDAY_SHOPPING_DOLLARS = 'HOLIDAY_SHOPPING_DOLLARS';
export const COME_AND_GET = 'COME_AND_GET';
export const FREE_PLAY = 'FREE_PLAY';
export const SPA_SPECIAL = 'SPA_SPECIAL';
export const TOURNAMENT = 'TOURNAMENT';
export const ONLINE_DRAWING = 'ONLINE_DRAWING';
export const ONLINE_TOURNAMENT = 'ONLINE_TOURNAMENT';

// Promo Perks
export const PRIZE_MULTIPLIER = 'PRIZE_MULTIPLIER';
export const AUTO_ADVANCE = 'AUTO_ADVANCE';
export const AUTO_ADVANCE_PLUS = 'AUTO_ADVANCE_PLUS';
export const PRIZE_BOOSTER = 'PRIZE_BOOSTER';
export const PICK_FIRST = 'PICK_FIRST';
export const VIRTUAL_WIN = 'VIRTUAL_WIN';
export const WIN_FROM_HOME = 'WIN_FROM_HOME';
export const ADD_A_FRIEND = 'ADD_A_FRIEND';
export const MYSTERY_BOX = 'MYSTERY_BOX';
export const CASH_IS_KING = 'CASH_IS_KING';
export const EXTRA_ENTRIES = 'EXTRA_ENTRIES';
export const EXTRA_EXTRA = 'EXTRA_EXTRA';
export const FREE_ENTRY = 'FREE_ENTRY';
export const DECKED_OUT = 'DECKED_OUT';
export const ALL_MINE = 'ALL_MINE';
export const FREE_FOR_ME = 'FREE_FOR_ME';
export const TRIGGERED = 'TRIGGERED';
export const FREE_FOOD_FRENZY = 'FREE_FOOD_FRENZY';
export const WATERFALL = 'WATERFALL';
export const SHARE_THE_WIN = 'SHARE_THE_WIN';
export const OH_SNAP = 'OH_SNAP';

// community event category types -- these values match the values in the database.
export const PROMOTIONS = 'promotions';
export const ENTERTAINMENT = 'entertainment';
export const SPECIAL = 'special';

// Property constants

export const PROPERTY_ONLINE = 'ONLINE';
export const PROPERTY_ATMORE = 'WCA';
export const PROPERTY_BETHLEHEM = 'WCB';
export const PROPERTY_MONTGOMERY = 'WCM';
export const PROPERTY_WETUMPKA = 'WCW';
export const PROPERTY_WASHESHU = 'WASHESHU';
export const PROPERTY_ARUBA = 'ARUBA';
export const PROPERTY_CURACAO = 'CURACAO';

export const PROPERTIES = {
  [PROPERTY_ATMORE]: 'Atmore',
  [PROPERTY_BETHLEHEM]: 'Bethlehem',
  [PROPERTY_MONTGOMERY]: 'Montgomery',
  [PROPERTY_WETUMPKA]: 'Wetumpka'
};

export const PROPERTY_TIME_ZONE = {
  [PROPERTY_ONLINE]: 'America/Chicago',
  [PROPERTY_ATMORE]: 'America/Chicago',
  [PROPERTY_BETHLEHEM]: 'America/New_York',
  [PROPERTY_MONTGOMERY]: 'America/Chicago',
  [PROPERTY_WETUMPKA]: 'America/Chicago',
  [PROPERTY_WASHESHU]: 'America/Los_Angeles',
  [PROPERTY_ARUBA]: 'America/Aruba',
  [PROPERTY_CURACAO]: 'America/Curacao'
};

export const DEFAULT_LOCATIONS = [
  'Atmore',
  'Montgomery',
  'Wetumpka',
  'Wa She Shu',
  'Aruba',
  'Curacao',
  'Bethlehem'
];

// Gem Types
export const NUGGET = 'NUGGET';
export const RUBY = 'RUBY';
export const SAPPHIRE = 'SAPPHIRE';
export const WCHIP = 'WCHIP';
export const WSCORE = 'WSCORE';
